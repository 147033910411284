import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import React, { Dispatch, SetStateAction } from "react";
import ScrollableContainer from "./ScrollableContainer";
import { Filter } from "../types/Filter";
import "./DynamicThemeFilter.css";

type DynamicThemeFilterProps<T> = {
    filters: Filter<T>[];
    setActiveFilter: Dispatch<SetStateAction<T | "all">>;
    activeFilter: string;
};
function DynamicThemeFilter<T extends string>(
    props: DynamicThemeFilterProps<T>,
) {
    const { filters, setActiveFilter, activeFilter } = props;
    const t = useAlbertineTranslation();

    return (
        <ScrollableContainer className="dynamic-theme-filter__wrapper">
            <Lib.Button.Pill
                className="dynamic-theme-filter__button"
                key="filterOff"
                selected={activeFilter === "all"}
                onClick={() => setActiveFilter("all")}
            >
                {t("offering_category.all")}
            </Lib.Button.Pill>
            {filters?.map((filter) => (
                <Lib.Button.Pill
                    key={filter.id}
                    className="dynamic-theme-filter__button"
                    selected={activeFilter === filter.id}
                    onClick={() => setActiveFilter(filter.id)}
                >
                    {filter.text}
                </Lib.Button.Pill>
            ))}
        </ScrollableContainer>
    );
}

export default DynamicThemeFilter;
