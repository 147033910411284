import { Lib, useUILanguage } from "albertine-shared-web";
import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { getStaticThemeArticles } from "../../../lmt/src/common/utils/opensearch/themes";
import {
    findTranslations,
    OpenSearchCatalogHitSource,
} from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import { useOpenSearch } from "../context/opensearch";
import MemberTopThemesItem from "./MemberTopThemesItem";
import "./MemberTopThemes.css";
import { useScreenStack } from "../context/screenStack";
import AlbertineSwiper from "./AlbertineSwiper";
import {
    findThemeTranslations,
    OpenSearchTheme,
} from "../../../lmt/src/common/types/OpenSearch.Theme";

interface MemberTopThemesProps {
    index: number;
    theme: OpenSearchTheme;
}
function MemberTopThemes(props: MemberTopThemesProps) {
    const { theme, index } = props;
    const [articles, setArticles] = useState<OpenSearchCatalogHitSource[]>();
    const currentUILanguage = useUILanguage();
    const { opensearch } = useOpenSearch();
    const { openArticle } = useScreenStack();
    const themeTranslations = findThemeTranslations(theme, currentUILanguage);
    useEffect(() => {
        async function fetchArticles() {
            if (opensearch) {
                const themeArticles = await getStaticThemeArticles(
                    opensearch,
                    theme,
                );
                setArticles(themeArticles);
            }
        }
        fetchArticles();
    }, [theme, opensearch]);

    return (
        <Lib.Flex.Column gap="value16" className="member-top-themes__item">
            <Lib.Flex.Row className="member-top-themes__title">
                <Lib.Heading.H4.Bold>
                    {themeTranslations.name}
                </Lib.Heading.H4.Bold>
            </Lib.Flex.Row>
            <Lib.Flex.Row className="member-top-themes__items">
                <AlbertineSwiper
                    spaceBetween={8}
                    breakpoints={{
                        720: {
                            spaceBetween: 12,
                        },
                    }}
                    className={classNames(
                        index !== 2 ? "member-top-themes__swiper" : "",
                    )}
                >
                    {articles?.map((article: OpenSearchCatalogHitSource) => {
                        const { id, images } = article;
                        const translations = findTranslations(
                            article,
                            currentUILanguage,
                        );
                        return (
                            <SwiperSlide
                                key={article.id}
                                className="member-top-themes__slide"
                            >
                                <MemberTopThemesItem
                                    index={index}
                                    name={translations.name}
                                    keyDetail={translations.keyDetail}
                                    background={images ? images[0] : ""}
                                    onClick={() => openArticle(id)}
                                />
                            </SwiperSlide>
                        );
                    })}
                </AlbertineSwiper>
            </Lib.Flex.Row>
        </Lib.Flex.Column>
    );
}
export default MemberTopThemes;
