import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import React from "react";
import Screen from "./Screen";
import ListenToRequests from "../loaders/ListenToRequests";
import { MemberRequest } from "../../../lmt/src/common/types/Request";
import Avatar from "../components/Avatar";
import ListenToConversation from "../loaders/ListenToConversation";
import ListenToMember from "../loaders/ListenToMember";
import { Conversation } from "../../../lmt/src/common/types/Conversation";
import "./Conversations.css";
import { Message } from "../../../lmt/src/common/types/Message";
import { ListenToLatestMessageInThread } from "../loaders/ListenToConversationMessages";
import {
    filterClosed,
    filterInProgress,
    filterMemberToReview,
    formatDeadline,
    getLastMessageSender,
    getUnreadMessagesCount,
    shouldShowUnreadBadge,
} from "../utils/threads.util";
import { useScreenStack } from "../context/screenStack";
import Glow from "../components/Glow";

type StatusBucket = "review" | "in-progress" | "closed";

function ThreadSnippet(props: {
    conversation: Conversation;
    request: MemberRequest;
    lastMessage: Message | undefined;
    statusBucket: StatusBucket;
}) {
    const { conversation, request, lastMessage, statusBucket } = props;
    const t = useAlbertineTranslation();

    const time = Lib.Utils.TextFormatter.proposal.time(
        request.startDate || undefined,
        request.startTime || undefined,
        request.endDate || undefined,
        request.endTime || undefined,
    ).requestStartTime;
    const numberOfGuests = Lib.Utils.TextFormatter.proposal.numberOfGuests(
        request.numberOfGuests || undefined,
    );
    const details = Lib.Utils.Strings.combineWith(
        [time, numberOfGuests],
        " · ",
    );
    const formattedDeadline = formatDeadline(request, new Date());

    const { unreadCount, showUnreadCount } = shouldShowUnreadBadge(
        conversation,
        request,
        lastMessage,
    );
    const { avatarType, senderId, senderFullName } = getLastMessageSender(
        request,
        lastMessage,
    );

    const { openChat } = useScreenStack();

    const avatar = (
        <div className="conversations__avatar-wrapper">
            <Glow>
                {avatarType === "agent" && (
                    <Avatar.Agent.Medium
                        id={senderId}
                        fullName={senderFullName}
                    />
                )}

                {avatarType === "member" && (
                    <Avatar.Member.Medium
                        id={senderId}
                        fullName={senderFullName}
                    />
                )}

                {avatarType === "service-agent" && (
                    <Avatar.ServiceAgent.Medium
                        id={senderId}
                        fullName={senderFullName}
                    />
                )}
            </Glow>

            {showUnreadCount ? (
                <div className="conversations__thread-unread-badge">
                    <Lib.Badge count={unreadCount} />
                </div>
            ) : null}
        </div>
    );

    const snippet = (
        <Lib.Flex.Column
            gap="value8"
            className="conversations__thread-snippet-title-wrapper"
        >
            {request.status === "new" ? (
                <>
                    <Lib.Label.Medium.Bold.TextColor01 className="conversations__thread-snippet-title">
                        {request.title}
                    </Lib.Label.Medium.Bold.TextColor01>
                    {details && (
                        <Lib.Label.Medium.Reqular.TextColor03>
                            {details}
                        </Lib.Label.Medium.Reqular.TextColor03>
                    )}
                    <Lib.Tag.Secondary>
                        {t("conversations__request-received")}
                    </Lib.Tag.Secondary>
                </>
            ) : (
                <>
                    {avatarType === "member" ? (
                        <Lib.Flex.Row gap="value8">
                            <Lib.Icon.Reply.Small.Gray40 />
                            <Lib.Label.Medium.Bold.TextColor01 className="conversations__thread-snippet-title">
                                {request.title}
                            </Lib.Label.Medium.Bold.TextColor01>
                        </Lib.Flex.Row>
                    ) : (
                        <Lib.Label.Medium.Bold.TextColor01 className="conversations__thread-snippet-title">
                            {request.title}
                        </Lib.Label.Medium.Bold.TextColor01>
                    )}

                    {details && (
                        <Lib.Label.Medium.Reqular.TextColor03>
                            {details}
                        </Lib.Label.Medium.Reqular.TextColor03>
                    )}

                    {statusBucket === "in-progress" && formattedDeadline && (
                        <Lib.Tag.Secondary>
                            {t("conversations__deadline-next-update", {
                                deadlineText: formattedDeadline,
                            })}
                        </Lib.Tag.Secondary>
                    )}

                    {statusBucket === "in-progress" &&
                        request.status === "confirmed" && (
                            <Lib.Tag.Secondary>
                                <Lib.FlexInline gap="value4">
                                    <Lib.Icon.Check.XSmall />
                                    <span>
                                        {t("conversations__request-confirmed")}
                                    </span>
                                </Lib.FlexInline>
                            </Lib.Tag.Secondary>
                        )}
                </>
            )}
        </Lib.Flex.Column>
    );

    return (
        <Lib.Flex.Row
            gap="value12"
            key={request.id}
            alignItems={details ? "start" : "center"}
            onClick={() => openChat(request.id)}
        >
            {avatar}

            {snippet}
        </Lib.Flex.Row>
    );
}

function ThreadSnippets(props: {
    conversation: Conversation;
    requests: MemberRequest[];
    statusBucket: StatusBucket;
}) {
    const { conversation, requests, statusBucket } = props;
    return requests.map((request) => (
        <ListenToLatestMessageInThread
            key={request.id}
            conversationId={conversation.id}
            requestId={request.id}
        >
            {(lastMessage) => (
                <ThreadSnippet
                    conversation={conversation}
                    request={request}
                    lastMessage={lastMessage}
                    statusBucket={statusBucket}
                />
            )}
        </ListenToLatestMessageInThread>
    ));
}

type ConversationsProps = {
    currentMemberId: string;
};

function Conversations(props: ConversationsProps) {
    const { currentMemberId } = props;
    const t = useAlbertineTranslation();
    const { openChat } = useScreenStack();

    const allMessagesButton = (conversation: Conversation) => {
        const unreadMessagesInGeneralChat = getUnreadMessagesCount(
            conversation,
            undefined,
        );

        return (
            <Lib.Flex.Column
                gap="value32"
                className="conversations__start-conversation-wrapper"
            >
                <Lib.Flex.Row
                    gap="value16"
                    alignItems="start"
                    className="conversations__start-conversation"
                >
                    <div className="conversations__avatar-wrapper">
                        <Glow>
                            <Avatar.ServiceAgent.Large id={undefined} />
                        </Glow>

                        {unreadMessagesInGeneralChat ? (
                            <div className="conversations__thread-unread-badge">
                                <Lib.Badge
                                    count={unreadMessagesInGeneralChat}
                                />
                            </div>
                        ) : null}
                    </div>

                    <Lib.Flex.Column
                        gap="value8"
                        className="conversations__thread-snippet-title-wrapper"
                        onClick={() => openChat("all")}
                    >
                        <Lib.Label.Medium.Bold.TextColor01>
                            {t("conversations__all-messages")}
                        </Lib.Label.Medium.Bold.TextColor01>
                        <Lib.Label.Medium.Reqular.TextColor03>
                            {unreadMessagesInGeneralChat
                                ? t("conversations__new-messages", {
                                      count: unreadMessagesInGeneralChat,
                                  })
                                : t("conversations__request-or-send")}
                        </Lib.Label.Medium.Reqular.TextColor03>
                    </Lib.Flex.Column>
                </Lib.Flex.Row>

                <Lib.Line.Dashed />
            </Lib.Flex.Column>
        );
    };

    const showYourRequests = (
        conversation: Conversation,
        requests: MemberRequest[],
    ) =>
        [
            ...filterMemberToReview(conversation, requests),
            ...filterInProgress(conversation, requests),
        ].length !== 0;

    const pleaseReview = (
        conversation: Conversation,
        requests: MemberRequest[],
    ) => {
        const filteredRequests = filterMemberToReview(conversation, requests);
        return filteredRequests.length !== 0 ? (
            <Lib.Flex.Column gap="value24">
                <Lib.Heading.H6.Bold>
                    {t("conversations__requests-review")}
                </Lib.Heading.H6.Bold>

                <Lib.Flex.Column gap="value24">
                    <ThreadSnippets
                        conversation={conversation}
                        requests={filteredRequests}
                        statusBucket="review"
                    />
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        ) : null;
    };

    const inProgress = (
        conversation: Conversation,
        requests: MemberRequest[],
    ) => {
        const filteredRequests = filterInProgress(conversation, requests);
        return filteredRequests.length !== 0 ? (
            <Lib.Flex.Column gap="value24">
                <Lib.Heading.H6.Bold>
                    {t("conversations__requests-in-progress")}
                </Lib.Heading.H6.Bold>

                <Lib.Flex.Column gap="value24">
                    <ThreadSnippets
                        conversation={conversation}
                        requests={filteredRequests}
                        statusBucket="in-progress"
                    />
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        ) : null;
    };

    const closed = (conversation: Conversation, requests: MemberRequest[]) => {
        const filteredRequests = filterClosed(requests);
        return filteredRequests.length !== 0 ? (
            <Lib.Flex.Column gap="value32">
                <Lib.Heading.H4.XBold>
                    {t("conversations__closed-requests")}
                </Lib.Heading.H4.XBold>

                <Lib.Flex.Column gap="value24">
                    <ThreadSnippets
                        conversation={conversation}
                        requests={filteredRequests}
                        statusBucket="closed"
                    />
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        ) : null;
    };

    return (
        <Screen.ConversationsBg01>
            <Lib.Flex.Column className="conversations">
                <ListenToMember memberId={currentMemberId}>
                    {(member) => (
                        <ListenToConversation
                            conversationId={member.conversation}
                        >
                            {(conversation) => (
                                <>
                                    <Lib.Flex.Row
                                        justifyContent="center"
                                        className="conversations__header"
                                    >
                                        <Lib.Heading.H5.Bold>
                                            {t("conversations_screen_title")}
                                        </Lib.Heading.H5.Bold>
                                    </Lib.Flex.Row>

                                    <Lib.Flex.Column
                                        gap="value32"
                                        className="conversations__content"
                                    >
                                        {allMessagesButton(conversation)}

                                        <ListenToRequests memberId={member.id}>
                                            {(requests) => (
                                                <Lib.Flex.Column
                                                    gap="value48"
                                                    className="conversations__threads"
                                                >
                                                    <Lib.Flex.Column gap="value32">
                                                        {showYourRequests(
                                                            conversation,
                                                            requests,
                                                        ) && (
                                                            <Lib.Heading.H3.XBold>
                                                                {t(
                                                                    "conversations__your-requests",
                                                                )}
                                                            </Lib.Heading.H3.XBold>
                                                        )}

                                                        {pleaseReview(
                                                            conversation,
                                                            requests,
                                                        )}

                                                        {inProgress(
                                                            conversation,
                                                            requests,
                                                        )}
                                                    </Lib.Flex.Column>

                                                    {closed(
                                                        conversation,
                                                        requests,
                                                    )}
                                                </Lib.Flex.Column>
                                            )}
                                        </ListenToRequests>
                                    </Lib.Flex.Column>
                                </>
                            )}
                        </ListenToConversation>
                    )}
                </ListenToMember>
            </Lib.Flex.Column>
        </Screen.ConversationsBg01>
    );
}

export default Conversations;
