import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    Lib,
    Trans,
    useAlbertineTranslation,
    useTenant,
    useUILanguage,
} from "albertine-shared-web";
import Header, { BackButton } from "../components/Header";
import Screen from "./Screen";
import { useOpenSearch } from "../context/opensearch";
import { getStaticThemeArticles } from "../../../lmt/src/common/utils/opensearch/themes";
import { DynamicTheme as DynamicThemeProps } from "../../../lmt/src/common/types/DynamicTheme";
import { OpenSearchCatalogHitSource } from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import ScreenHeader from "../components/ScreenHeader";
import "./DynamicTheme.css";
import { useScreenStack } from "../context/screenStack";
import DynamicThemeCard from "../components/DynamicThemeCard";
import DynamicThemeFilter from "../components/DynamicThemeFilter";
import { Filter } from "../types/Filter";

type DynamicTheme<T> = {
    data: DynamicThemeProps;
    name: string;
    filters: Filter<T>[];
    filterFn: (
        articles: OpenSearchCatalogHitSource,
        activeFilter: T,
    ) => boolean;
    cleanupFilters: (
        articles: OpenSearchCatalogHitSource[] | [],
        filters: Filter<T | "all">[],
    ) => boolean;
};

function DynamicTheme<T extends string>(props: DynamicTheme<T>) {
    const { data, filters, name, filterFn, cleanupFilters } = props;
    const currentUILanguage = useUILanguage();
    const t = useAlbertineTranslation();
    const { opensearch } = useOpenSearch();
    const { tenant } = useTenant();
    const { openArticle, openChat } = useScreenStack();

    const [initialData, setInitialData] = useState<
        OpenSearchCatalogHitSource[] | null
    >(null);
    const [availableFilters, setAvailableFilters] = useState<Filter<T>[]>([]);
    const [activeFilter, setActiveFilter] = useState<T | "all">("all");
    const [isFirstRender, setIsFirstRender] = useState(true);

    const targetRef = useRef<HTMLDivElement | null>(null);

    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    useEffect(() => {
        async function fetchArticles() {
            if (opensearch && data) {
                await getStaticThemeArticles(opensearch, data).then(
                    (articles: OpenSearchCatalogHitSource[]) => {
                        setInitialData(articles);
                    },
                );
            }
            return () => {};
        }
        fetchArticles();
    }, [currentUILanguage, data, opensearch]);

    const filteredData: OpenSearchCatalogHitSource[] = useMemo(() => {
        if (!initialData) return [];
        if (activeFilter === "all") return initialData;

        return initialData.filter((article) => filterFn(article, activeFilter));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData, activeFilter, data.type]);

    useEffect(() => {
        const filtersFromData = filters.filter((filter) =>
            cleanupFilters(filteredData, [filter]),
        );
        setAvailableFilters(filtersFromData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    useEffect(() => {
        if (!isFirstRender) {
            scrollToTarget();
        } else {
            setIsFirstRender(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter]);

    return (
        <Screen.DiscoverBg04 className="dynamic-theme__screen-bg">
            <Header
                title={t("dynamic-theme__title-topbar", {
                    themeName: name,
                })}
                type="dynamicTheme"
            />

            <Lib.Background.DiscoverBg04 className="dynamic-theme__screen-bg">
                <Lib.Flex.Column>
                    <Lib.Background.DiscoverBg01
                        className="dynamic-theme__bg"
                        textColorInversable={tenant === "hugoboss"}
                    >
                        <BackButton />

                        <Lib.ContentCenter.Large>
                            <ScreenHeader
                                primaryTitle={
                                    <Trans
                                        i18nKey="dynamic-theme__title"
                                        components={[
                                            <em
                                                key="dynamic-theme-title"
                                                className="dynamic-theme__title--italic"
                                            />,
                                        ]}
                                        values={{
                                            themeName: name,
                                        }}
                                    />
                                }
                                className="dynamic-theme__header"
                            />
                        </Lib.ContentCenter.Large>
                    </Lib.Background.DiscoverBg01>
                    <Lib.ContentCenter.Large className="dynamic-theme__main-image">
                        <Lib.RemoteImage.Fluid src={data?.image} />
                    </Lib.ContentCenter.Large>
                    <Lib.Background.DiscoverBg04 className="dynamic-theme__content-section">
                        <Lib.Flex.Column
                            gap="value40"
                            className="dynamic-theme__filter-wrapper"
                        >
                            <Lib.Flex.Column
                                gap="value24"
                                className="dynamic-theme__sticky-filter"
                            >
                                <Lib.ContentCenter.Large>
                                    <Lib.Heading.H4.Bold>
                                        {t("dynamic-theme__recommendations", {
                                            count: filteredData.length,
                                        })}
                                    </Lib.Heading.H4.Bold>
                                </Lib.ContentCenter.Large>
                                {initialData?.length !== 0 && (
                                    <DynamicThemeFilter
                                        filters={availableFilters}
                                        setActiveFilter={setActiveFilter}
                                        activeFilter={activeFilter}
                                    />
                                )}
                            </Lib.Flex.Column>
                            <Lib.ContentCenter.Large>
                                <Lib.Flex.Column
                                    gap="value32"
                                    className="dynamic-theme__content-items-container"
                                    ref={targetRef}
                                >
                                    {filteredData?.map((article) => (
                                        <Lib.Flex.Column
                                            key={article.id}
                                            className="dynamic-theme__content-item"
                                            gap="value24"
                                            onClick={() =>
                                                openArticle(article.id)
                                            }
                                        >
                                            <DynamicThemeCard
                                                article={article}
                                                language={currentUILanguage}
                                            />
                                        </Lib.Flex.Column>
                                    ))}
                                </Lib.Flex.Column>
                            </Lib.ContentCenter.Large>
                        </Lib.Flex.Column>
                    </Lib.Background.DiscoverBg04>

                    <Lib.Background.DiscoverBg01
                        textColorInversable={tenant === "hugoboss"}
                    >
                        <Lib.ContentCenter.Medium className="dynamic-theme__footer">
                            <Lib.Flex.Column gap="value24" alignItems="center">
                                <Lib.Paragraph.Medium.Reqular className="dynamic-theme__footer-text">
                                    {t("dynamic-theme__footer__text")}
                                </Lib.Paragraph.Medium.Reqular>
                                <Lib.Line.Vertical />
                                <Lib.Button.Primary
                                    className="dynamic-theme__footer-button"
                                    onClick={() => openChat("all")}
                                >
                                    {t("dynamic-theme__footer__button")}
                                </Lib.Button.Primary>
                            </Lib.Flex.Column>
                        </Lib.ContentCenter.Medium>
                    </Lib.Background.DiscoverBg01>
                </Lib.Flex.Column>
            </Lib.Background.DiscoverBg04>
        </Screen.DiscoverBg04>
    );
}

export default DynamicTheme;
