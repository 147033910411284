import React from "react";
import "./EmailInput.css";
import classNames from "classnames";
import { Lib } from "albertine-shared-web";

interface EmailInputProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    placeholder: string;
    className?: string;
    autoFocus?: boolean;
    loading?: boolean;
    defaultValue?: string;
}

function NumberInput(props: EmailInputProps) {
    const {
        onChange,
        placeholder,
        className,
        loading,
        autoFocus = true,
        defaultValue,
    } = props;

    const emailInputClass = classNames("email_input ", className);

    return (
        <Lib.Flex.Row>
            <input
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                placeholder={placeholder}
                className={emailInputClass}
                onChange={onChange}
                inputMode="email"
                defaultValue={defaultValue}
            />
            {loading && <Lib.LoadingSpinner />}
        </Lib.Flex.Row>
    );
}

export default NumberInput;
