import { z } from "zod";
import { translation } from "albertine-shared-web";
import { DynamicTheme, ThemeLocation } from "./DynamicTheme";
import { OfferingCategory } from "./OfferingCategory";
import {
    cityGuideQuery,
    localEventsQuery,
    newRestaurantsQuery,
    offeringCategoriesQuery,
} from "./OpenSearch.CatalogItem";
import { cityGuideKey } from "../utils/catalog.util";

export const FixedPublishedAt = "2023-01-01T00:00:00.000Z";

export const CityGuideConfig = DynamicTheme.pick({
    key: true,
    name: true,
    image: true,
    translations: true,
});
export type CityGuideConfig = z.infer<typeof CityGuideConfig>;

export const CategoryConfig = DynamicTheme.pick({
    key: true,
    name: true,
    image: true,
    translations: true,
}).merge(z.object({ offeringCategories: z.array(OfferingCategory) }));

export const ContentConfiguration = z.object({
    cityGuides: z.array(CityGuideConfig),
    categories: z.array(CategoryConfig),
});
export type ContentConfiguration = z.infer<typeof ContentConfiguration>;

export const OpenSearchContentConfiguration = z.object({
    cityGuides: z.array(DynamicTheme),
    categories: z.array(DynamicTheme),
});

export type OpenSearchContentConfiguration = z.infer<
    typeof OpenSearchContentConfiguration
>;

export function toOpenSearchContentConfiguration(
    config: ContentConfiguration,
): OpenSearchContentConfiguration {
    const cityGuides = config.cityGuides.map(
        (cityGuide): DynamicTheme => ({
            key: cityGuide.key,
            name: cityGuide.name,
            image: cityGuide.image,
            slugs: null,
            query: JSON.stringify(cityGuideQuery(cityGuide.key)),
            type: "cityGuide",
            published: FixedPublishedAt,
            group: null,
            location: [],
            preferences: [],
            translations: cityGuide.translations,
        }),
    );
    const categories = config.categories.map(
        (category): DynamicTheme => ({
            key: category.key,
            name: category.name,
            image: category.image,
            slugs: null,
            query: JSON.stringify(
                offeringCategoriesQuery(category.offeringCategories),
            ),
            type: "category",
            published: FixedPublishedAt,
            group: null,
            location: [],
            preferences: [],
            translations: category.translations,
        }),
    );
    return { cityGuides, categories };
}

export function themeLocationToNewRestaurantTheme(
    location: ThemeLocation,
): DynamicTheme {
    if (location.city === null) {
        throw new Error("City is null");
    }
    const { city, countryCode } = location;
    const key = cityGuideKey({ city, countryCode });
    return {
        key: `${key}-new-restaurants`,
        name: translation("dynamic_theme.new_restaurants_in_city", {
            city,
            lang: "en",
        }),
        query: JSON.stringify(newRestaurantsQuery(key)),
        type: "dynamic",
        published: null,
        location: [location],
        preferences: ["dining"],
        image: null,
        slugs: null,
        group: { id: "new-restaurants", name: "New restaurants" },
        translations: {
            de: {
                name: translation("dynamic_theme.new_restaurants_in_city", {
                    city,
                    lang: "de",
                }),
            },
            fr: {
                name: translation("dynamic_theme.new_restaurants_in_city", {
                    city,
                    lang: "fr",
                }),
            },
        },
    };
}

export function themeLocationToLocalEventsTheme(
    location: ThemeLocation,
): DynamicTheme {
    if (location.city === null) {
        throw new Error("City is null");
    }
    const { city, countryCode } = location;
    const key = cityGuideKey({ city, countryCode });
    return {
        key: `${key}-local-events`,
        name: translation("dynamic_theme.upcoming_events_in_city", {
            city,
            lang: "en",
        }),
        query: JSON.stringify(localEventsQuery(key)),
        type: "dynamic",
        published: null,
        location: [location],
        preferences: ["localExperiences"],
        image: null,
        slugs: null,
        group: { id: "local-events", name: "Local events" },
        translations: {
            de: {
                name: translation("dynamic_theme.upcoming_events_in_city", {
                    city,
                    lang: "de",
                }),
            },
            fr: {
                name: translation("dynamic_theme.upcoming_events_in_city", {
                    city,
                    lang: "fr",
                }),
            },
        },
    };
}
