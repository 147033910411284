import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Lib, useUILanguage } from "albertine-shared-web";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import "./CityGuide.css";
import AlbertineSwiper from "./AlbertineSwiper";
import { useScreenStack } from "../context/screenStack";
import { findThemeTranslations } from "../../../lmt/src/common/types/OpenSearch.Theme";

type Props = {
    cities: DynamicTheme[];
};

function CityGuide(props: Props) {
    const { cities } = props;
    const { openCityGuide } = useScreenStack();
    const currentUILanguage = useUILanguage();

    return (
        <Lib.Flex.Row>
            <AlbertineSwiper
                spaceBetween={12}
                breakpoints={{
                    720: {
                        spaceBetween: 16,
                    },
                }}
            >
                {cities.map((city: DynamicTheme) => {
                    const translations = findThemeTranslations(
                        city,
                        currentUILanguage,
                    );

                    return (
                        <SwiperSlide
                            key={city.key}
                            className="web-lib__cityguide-item"
                        >
                            <Lib.Button.Ghost
                                onClick={() => openCityGuide(city)}
                            >
                                {city.image && (
                                    <Lib.RemoteImage.Rounded
                                        src={city.image}
                                        alt={translations.name}
                                    />
                                )}
                                <div className="text-color-inversable web-lib__cityguide-city">
                                    <Lib.Heading.H6.Bold>
                                        {translations.name}
                                    </Lib.Heading.H6.Bold>
                                </div>
                            </Lib.Button.Ghost>
                        </SwiperSlide>
                    );
                })}
            </AlbertineSwiper>
        </Lib.Flex.Row>
    );
}

export default CityGuide;
