import {
    Lib,
    useAlbertineTranslation,
    useUILanguage,
} from "albertine-shared-web";
import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { getStaticThemeArticles } from "../../../lmt/src/common/utils/opensearch/themes";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import {
    findTranslations,
    OpenSearchCatalogHitSource,
} from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import { useOpenSearch } from "../context/opensearch";
import { useScreenStack } from "../context/screenStack";
import AlbertineSwiper from "./AlbertineSwiper";
import "./Spotlight.css";

function Spotlight(props: { theme: DynamicTheme }) {
    const { theme } = props;
    const currentUILanguage = useUILanguage();
    const [articles, setArticles] = useState<OpenSearchCatalogHitSource[]>();
    const { opensearch } = useOpenSearch();
    const { openArticle } = useScreenStack();
    const t = useAlbertineTranslation();

    useEffect(() => {
        async function fetchArticles() {
            if (opensearch) {
                const themeArticles = await getStaticThemeArticles(
                    opensearch,
                    theme,
                );
                setArticles(themeArticles);
            }
        }

        fetchArticles();
    }, [theme, opensearch]);

    return (
        <Lib.Flex.Row>
            <AlbertineSwiper
                spaceBetween={8}
                breakpoints={{
                    720: {
                        spaceBetween: 12,
                    },
                }}
            >
                {articles?.map((article: OpenSearchCatalogHitSource) => {
                    const { id, images } = article;

                    const translations = findTranslations(
                        article,
                        currentUILanguage,
                    );
                    return (
                        <SwiperSlide
                            key={id}
                            className="web-lib__spotlight-slide"
                        >
                            <Lib.SpotlightItem
                                tag={t("spotlight-item__tag")}
                                name={translations.name}
                                keyDetail={translations.keyDetail}
                                background={images ? images[0] : ""}
                                onClick={() => openArticle(id)}
                            />
                        </SwiperSlide>
                    );
                })}
            </AlbertineSwiper>
        </Lib.Flex.Row>
    );
}

export default Spotlight;
