import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { FirebaseId } from "../../../lmt/src/common/validate";
import { routes } from "../routes";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import { Filter } from "../types/Filter";

const WebsiteSource = z.enum(["terms", "privacy"]);
const FilterSchema = z.object({
    id: z.string(),
    text: z.string(),
});
type FilterSchema = z.infer<typeof FilterSchema>;

const ScreenStackItem = z.discriminatedUnion("type", [
    z.object({
        type: z.literal("article"),
        id: z.string(),
    }),
    z.object({
        type: z.literal("booking"),
        id: FirebaseId,
    }),
    z.object({
        type: z.literal("chat"),
        id: z.literal("all").or(FirebaseId),
    }),
    z.object({
        type: z.literal("proposal"),
        id: FirebaseId,
    }),
    z.object({
        type: z.literal("profile"),
        id: z.string(),
    }),
    z.object({
        type: z.literal("website"),
        id: z.string(),
        source: WebsiteSource,
    }),
    z.object({
        type: z.literal("cityGuide"),
        id: z.string(),
        data: DynamicTheme,
    }),
    z.object({
        type: z.literal("category"),
        id: z.string(),
        data: DynamicTheme,
        cities: z.array(FilterSchema),
    }),
]);
const ScreenStack = z.array(ScreenStackItem);
type WebsiteSource = z.infer<typeof WebsiteSource>;
export type ScreenStackItem = z.infer<typeof ScreenStackItem>;
export type ScreenStack = z.infer<typeof ScreenStack>;

type OverlayStackContextType = {
    openArticle: (articleId: string) => void;
    openBooking: (bookingId: string) => void;
    openChat: (chatId: string, baseUrl?: string) => void;
    openProposal: (requestId: string) => void;
    openProfile: () => void;
    openExternalWebsite: (source: "terms" | "privacy") => void;
    openCityGuide: (data: DynamicTheme) => void;
    openCategory: (
        data: DynamicTheme,
        cities: Filter<string | "all">[],
    ) => void;
    closeStackScreen: () => void;
    closeAllStacks: () => void;
    screenStack: ScreenStack;
    getReactKey: (stackItem: ScreenStackItem, index: number) => React.Key;
};
const OverlayStackContext = createContext<OverlayStackContextType | undefined>(
    undefined,
);

const urlParamName = "stacks";

function isPreviousStackItemChat(currentStack: ScreenStack): boolean {
    const lastStackItem =
        currentStack.length > 1
            ? currentStack[currentStack.length - 2]
            : undefined;

    if (lastStackItem && lastStackItem.type === "chat") {
        return true;
    }

    return false;
}

function getReactKey(stackItem: ScreenStackItem, index: number): React.Key {
    const { type, id } = stackItem;
    return `${type}_${id}_${index}`;
}

function addNewStackItem(
    prevStack: ScreenStack,
    newStackItem: ScreenStackItem,
): ScreenStack {
    const currentItem = prevStack[prevStack.length - 1];

    // Prevent adding exact same stacks on top of each other
    if (currentItem) {
        const currentItemJSON = JSON.stringify(currentItem);
        const newItemJSON = JSON.stringify(newStackItem);

        if (currentItemJSON !== newItemJSON) {
            return [...prevStack, newStackItem];
        }
        return prevStack;
    }

    return [...prevStack, newStackItem];
}

function getStackUrl(baseUrl: string | undefined, stack: ScreenStack) {
    return `${baseUrl || ""}?${urlParamName}=${encodeURIComponent(JSON.stringify(stack))}`;
}

export function getConversationThreadUrl(request: string | undefined) {
    const stack: ScreenStack = request
        ? [{ type: "chat", id: request }]
        : [{ type: "chat", id: "all" }];
    return getStackUrl(routes.url.conversations, stack);
}

function ScreenStackProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [screenStack, setScreenStack] = useState<ScreenStack>([]);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const updateUrl = (stack: ScreenStack, baseUrl?: string) => {
        navigate(getStackUrl(baseUrl, stack));
    };

    const goBack = () => {
        navigate(-1);
    };

    const closeStackScreen = () => {
        const newStack: ScreenStack = screenStack.slice(0, -1);
        setScreenStack(newStack);
        goBack();
    };

    const closeAllStacks = useCallback(() => {
        setScreenStack([]);
    }, []);

    const openArticle = (articleId: string) => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "article",
                id: articleId,
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    const openBooking = (bookingId: string) => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "booking",
                id: bookingId,
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    const openChat = (chatId: string, baseUrl?: string) => {
        setScreenStack((prevStack) => {
            if (isPreviousStackItemChat(prevStack)) {
                const newStack: ScreenStack = screenStack.slice(0, -1);
                goBack();
                return newStack;
            }
            const newStack = addNewStackItem(prevStack, {
                type: "chat",
                id: chatId,
            });
            updateUrl(newStack, baseUrl);
            return newStack;
        });
    };

    const openProposal = (requestId: string) => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "proposal",
                id: requestId,
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    const openProfile = () => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "profile",
                id: "profile",
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    const openExternalWebsite = (source: WebsiteSource) => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "website",
                id: source,
                source,
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    const openCityGuide = (city: DynamicTheme) => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "cityGuide",
                id: city.key,
                data: city,
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    const openCategory = (
        category: DynamicTheme,
        cities: Filter<string | "all">[],
    ) => {
        setScreenStack((prevStack) => {
            const newStack = addNewStackItem(prevStack, {
                type: "category",
                id: category.key,
                data: category,
                cities,
            });
            updateUrl(newStack);
            return newStack;
        });
    };

    useEffect(() => {
        const stackParam = searchParams.get(urlParamName);
        if (stackParam) {
            try {
                const stackFromUrl = ScreenStack.safeParse(
                    JSON.parse(decodeURIComponent(stackParam)),
                );

                if (stackFromUrl.success) {
                    setScreenStack(stackFromUrl.data);
                } else {
                    updateUrl([]);
                    closeAllStacks();
                }
            } catch {
                updateUrl([]);
                closeAllStacks();
            }
        } else {
            closeAllStacks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, location.pathname]);

    const value = useMemo(
        () => ({
            openChat,
            openArticle,
            openBooking,
            openProposal,
            openProfile,
            openExternalWebsite,
            openCityGuide,
            openCategory,
            closeStackScreen,
            closeAllStacks,
            screenStack,
            getReactKey,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [screenStack],
    );

    return (
        <OverlayStackContext.Provider value={value}>
            {children}
        </OverlayStackContext.Provider>
    );
}

export const useScreenStack = (): OverlayStackContextType => {
    const context = useContext(OverlayStackContext);
    if (!context) {
        throw new Error(
            "useScreenStack must be used within a ScreenStackProvider",
        );
    }
    return context;
};

export default ScreenStackProvider;
