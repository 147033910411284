import { translation } from "albertine-shared-web";
import { Member } from "../../../lmt/src/common/types/Member";

function getMemberGreeting(member: Member): string {
    const { greetingsName, title, firstName, lastName } = member;

    if (greetingsName) {
        return translation("bookings__secondary-title", {
            username: greetingsName,
        });
    }
    if (title?.length !== 0)
        return translation("bookings__secondary-title-dear", {
            username: `${title} ${lastName}`,
        });

    return translation("bookings__secondary-title", {
        username: `${firstName} ${lastName}`,
    });
}

export default getMemberGreeting;
