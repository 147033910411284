import React from "react";
import { Lib } from "albertine-shared-web";
import Screen from "./Screen";
import "./Loading.css";

function LoadingScreen() {
    return (
        <Screen.StartBg01>
            <Lib.Flex.Column
                alignItems="center"
                justifyContent="center"
                className="loading-screen"
            >
                <Lib.LoadingSpinner />
            </Lib.Flex.Column>
        </Screen.StartBg01>
    );
}

export default LoadingScreen;
