import { z } from "zod";
import config from "../config";
import { logError } from "../error";
import { OnboardingTopCity } from "../../../lmt/src/common/types/OnboardingPreferences";

export const OnboardingTopCityListItem = OnboardingTopCity.extend({
    _uid: z.string(),
    coverImage: z.object({
        filename: z.string(),
    }),
}).transform(({ _uid, coverImage, ...rest }) => ({
    ...rest,
    id: _uid,
    image: coverImage.filename,
}));

export type OnboardingTopCityListItem = z.infer<
    typeof OnboardingTopCityListItem
>;

export const OnboardingTopCityList = z.array(OnboardingTopCityListItem);
export type OnboardingTopCityList = z.infer<typeof OnboardingTopCityList>;

const fetchTopCities = async (): Promise<OnboardingTopCityList> => {
    const list = config.topCitiesList;

    try {
        const response = await fetch(list);

        if (!response.ok) {
            console.error("Failed to fetch list of top cities from the web", {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
            });
        }
        const data = await response.json();

        if (
            data &&
            data.story &&
            data.story.content &&
            data.story.content.topCities
        ) {
            const parsedData = OnboardingTopCityList.safeParse(
                data.story.content.topCities,
            );

            if (parsedData.success) {
                return parsedData.data;
            }
        }
    } catch (error) {
        logError("fetchTopCities", error);
    }

    return [];
};

export default fetchTopCities;
