import React, {
    ChangeEvent,
    ChangeEventHandler,
    useEffect,
    useState,
} from "react";
import { useOutletContext } from "react-router-dom";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import "./OnboardingCommonStyles.css";
import Transition from "./OnboardingTransition";
import TextInput from "../../components/TextInput";
import { Member } from "../../../../lmt/src/common/types/Member";
import { OnboardingOutletContextType } from "../../types/OnboardingOutlet";
import { PreferencesPayload } from "../../../../lmt/src/common/types/PreferencesPayload";
import { logError } from "../../error";

interface InitialScreen {
    handleClick: (name: string) => void;
    callback: (screen: number) => void;
    member: Member;
}
function InitialScreen({ handleClick, callback, member }: InitialScreen) {
    const t = useAlbertineTranslation();

    const titleAndLastName = member?.title
        ? `${member?.title} ${member?.lastName}`
        : member?.lastName;
    const { firstName } = member;
    return (
        <Transition>
            <Lib.Flex.Column gap="value24">
                <Lib.Flex.Column gap="value8">
                    <Lib.Tag.Primary>
                        {t("onboarding__name__tag")}
                    </Lib.Tag.Primary>
                    <Lib.Paragraph.XXLarge.XBold>
                        {t("onboarding__name__title")}
                    </Lib.Paragraph.XXLarge.XBold>
                </Lib.Flex.Column>
                <Lib.Flex.Column gap="value8">
                    <Lib.Button.Onboarding
                        type="button"
                        onClick={() => handleClick(titleAndLastName)}
                    >
                        {titleAndLastName}
                    </Lib.Button.Onboarding>
                    <Lib.Button.Onboarding
                        type="button"
                        onClick={() => handleClick(firstName)}
                    >
                        {firstName}
                    </Lib.Button.Onboarding>
                    <Lib.Button.Onboarding
                        type="button"
                        onClick={() => callback(1)}
                    >
                        {t("onboarding__name__other__button")}
                    </Lib.Button.Onboarding>
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Transition>
    );
}

interface CustomNameScreen {
    usersName: string;
    handleCustomName: ChangeEventHandler<HTMLInputElement>;
    submitCustomName: () => void;
}
function CustomNameScreen(props: CustomNameScreen) {
    const { usersName, handleCustomName, submitCustomName } = props;
    const t = useAlbertineTranslation();
    return (
        <Transition>
            <Lib.Flex.Column gap="value48">
                <Lib.Flex.Column gap="value32">
                    <Lib.Flex.Column gap="value8">
                        <Lib.Tag.Primary>
                            {t("onboarding__name__tag")}
                        </Lib.Tag.Primary>
                        <Lib.Paragraph.XXLarge.XBold>
                            {t("onboarding__name__custom-name")}
                        </Lib.Paragraph.XXLarge.XBold>
                    </Lib.Flex.Column>
                    <Lib.Flex.Column gap="value16">
                        <Lib.Line.Dashed />
                        <TextInput
                            onChange={handleCustomName}
                            placeholder="Your name"
                            defaultValue={usersName}
                        />
                    </Lib.Flex.Column>
                </Lib.Flex.Column>

                <Lib.Flex.Column>
                    <Lib.Button.Onboarding
                        type="button"
                        onClick={submitCustomName}
                        disabled={usersName.trim().length < 3}
                    >
                        {t("onboarding__name__custom-name__button")}
                    </Lib.Button.Onboarding>
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Transition>
    );
}

interface GreetingScreen {
    usersName: string;
}
function GreetingScreen(props: GreetingScreen) {
    const { usersName } = props;
    const t = useAlbertineTranslation();
    return (
        <Lib.Flex.Column>
            <Transition>
                <Lib.Paragraph.XXLarge.XBold.TextColor01 className="onboarding-transition-message">
                    {t("onboarding__name__greeting", { name: usersName })}
                </Lib.Paragraph.XXLarge.XBold.TextColor01>
            </Transition>
        </Lib.Flex.Column>
    );
}

function OnboardingName() {
    const { member, updateMemberPreferences, setNextStep, setProgress } =
        useOutletContext<OnboardingOutletContextType>();
    const [usersName, setUsersName] = useState<string>("");
    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        setProgress(30);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAndWait = (
        update: (
            onboarding: PreferencesPayload,
            memberObject: Member,
        ) => Promise<any>,
        name: string,
        memberObject: Member,
    ) =>
        setTimeout(() => {
            const onboardingData: PreferencesPayload = {
                onboarding: {
                    greetingsName: name,
                },
            };

            update(onboardingData, memberObject)
                .then(() => {
                    setNextStep("cities");
                })
                .catch((error) => {
                    logError("updateOnboardingData", error);
                });
        }, 2000);

    const goToPreviousStep = () => setStep(step - 1);

    const handleClick = (name: string) => {
        setUsersName(name);
        setStep(2);
        showAndWait(updateMemberPreferences, name, member!);
    };

    const handleCustomName = (e: ChangeEvent<HTMLInputElement>) => {
        setUsersName(e.target.value);
    };

    const submitCustomName = () => {
        setStep(2);
        showAndWait(updateMemberPreferences, usersName, member!);
    };

    return member ? (
        <Lib.Flex.Column>
            {step === 1 && (
                <Lib.Button.Ghost
                    className="onboarding-back-button"
                    onClick={goToPreviousStep}
                >
                    <Lib.Icon.ArrowBack.Medium />
                </Lib.Button.Ghost>
            )}

            {step === 0 && (
                <InitialScreen
                    handleClick={handleClick}
                    callback={setStep}
                    member={member!}
                />
            )}

            {step === 1 && (
                <CustomNameScreen
                    usersName={usersName}
                    handleCustomName={handleCustomName}
                    submitCustomName={submitCustomName}
                />
            )}

            {step === 2 && <GreetingScreen usersName={usersName} />}
        </Lib.Flex.Column>
    ) : (
        <Lib.LoadingSpinner />
    );
}

export default OnboardingName;
