import React from "react";
import { Lib, useUILanguage } from "albertine-shared-web";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import { useScreenStack } from "../context/screenStack";
import {} from "../../../lmt/src/common/types/OfferingCategory";
import { Filter } from "../types/Filter";
import "./Category.css";
import { findThemeTranslations } from "../../../lmt/src/common/types/OpenSearch.Theme";

type Props = {
    categories: DynamicTheme[];
    cities: Filter<string | "all">[];
};

function Category(props: Props) {
    const { categories, cities } = props;
    const { openCategory } = useScreenStack();
    const currentUILanguage = useUILanguage();

    return (
        <Lib.Flex.Column>
            {categories.map((category) => {
                const translations = findThemeTranslations(
                    category,
                    currentUILanguage,
                );

                return (
                    <Lib.Flex.Row
                        key={category.key}
                        className="web-lib__category-item"
                        gap="value24"
                        onClick={() => openCategory(category, cities)}
                    >
                        <Lib.Flex.Column className="web-lib__category-item-bg">
                            {category.image && (
                                <Lib.RemoteImage.Square.Large
                                    src={category.image}
                                    alt={translations.name}
                                    className="web-lib__category-image"
                                />
                            )}
                        </Lib.Flex.Column>
                        <Lib.Flex.Column justifyContent="space-between">
                            <div className="web-lib__category-item-name">
                                <Lib.Heading.H6.Bold>
                                    {translations.name}
                                </Lib.Heading.H6.Bold>
                            </div>
                            <Lib.Line.Dashed />
                        </Lib.Flex.Column>
                    </Lib.Flex.Row>
                );
            })}
        </Lib.Flex.Column>
    );
}

export default Category;
