import React, { useRef, useState, useEffect } from "react";
import "./ScrollableContainer.css";
import { Lib } from "albertine-shared-web";

function ScrollableContainer({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [isScrolledLeft, setIsScrolledLeft] = useState<boolean>(true);
    const [isScrolledRight, setIsScrolledRight] = useState<boolean>(false);

    const scroll = (direction: string) => {
        if (scrollContainerRef.current) {
            const value = direction === "left" ? -200 : 200;
            scrollContainerRef.current.scrollBy({
                left: value,
                behavior: "smooth",
            });
        }
    };

    const checkScrollPosition = () => {
        const container = scrollContainerRef.current;
        if (container) {
            const isAtStart = container.scrollLeft === 0;
            const isAtEnd =
                container.scrollLeft + container.clientWidth >=
                container.scrollWidth;

            setIsScrolledLeft(isAtStart);
            setIsScrolledRight(isAtEnd);
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener("scroll", checkScrollPosition);
            checkScrollPosition();

            // resizeObserver can be removed if we don't check this on device orientation change
            const resizeObserver = new ResizeObserver(() => {
                checkScrollPosition();
            });
            resizeObserver.observe(container);

            return () => {
                if (container) {
                    container.removeEventListener(
                        "scroll",
                        checkScrollPosition,
                    );
                }
            };
        }
        return () => {};
    }, [children]);

    return (
        <Lib.ContentCenter.Large
            className={`scrollable-container ${className}`}
        >
            {!isScrolledLeft && (
                <Lib.Button.Ghost
                    type="button"
                    onClick={() => scroll("left")}
                    className="scrollable-container__button scrollable-container__button-left"
                >
                    <Lib.Icon.ChevronLeft.Large />
                </Lib.Button.Ghost>
            )}

            <div
                ref={scrollContainerRef}
                className="scrollable-container__content"
            >
                {children}
            </div>

            {!isScrolledRight && (
                <Lib.Button.Ghost
                    type="button"
                    onClick={() => scroll("right")}
                    className="scrollable-container__button scrollable-container__button-right"
                >
                    <Lib.Icon.ChevronRight.Large />
                </Lib.Button.Ghost>
            )}
        </Lib.ContentCenter.Large>
    );
}

export default ScrollableContainer;
