import React, { useState } from "react";
import {
    getUILanguage,
    Lib,
    useAlbertineTranslation,
    useTenant,
} from "albertine-shared-web";
import "./TermsAndConditions.css";
import { useScreenStack } from "../context/screenStack";
import Screen from "./Screen";

function TermsAndConditions() {
    const { tenantConfig } = useTenant();
    const t = useAlbertineTranslation();
    const { closeStackScreen } = useScreenStack();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleOnLoad = () => setIsLoading(false);

    return (
        <Screen.ProfileBg02>
            <Lib.Flex.Row
                justifyContent="center"
                className="terms-and-conditions__header"
            >
                <Lib.Button.Ghost onClick={closeStackScreen}>
                    <Lib.Icon.ArrowBack.Medium className="terms-and-conditions__back-button" />
                </Lib.Button.Ghost>

                <div className="terms-and-conditions__title">
                    <Lib.Heading.H5.Bold>
                        {t("profile__terms-and-conditions")}
                    </Lib.Heading.H5.Bold>
                </div>
            </Lib.Flex.Row>
            {isLoading && (
                <Lib.LoadingSpinner className="terms-and-conditions__loading-spinner" />
            )}
            <iframe
                src={
                    tenantConfig.externalLinks.termsAndConditions[
                        getUILanguage().lang
                    ]
                }
                title={t("albertine__terms-and-conditions")}
                className="terms-and-conditions__iframe"
                onLoad={handleOnLoad}
            />
        </Screen.ProfileBg02>
    );
}

export default TermsAndConditions;
