/* eslint-disable no-underscore-dangle */

import * as z from "zod";
import {
    AdditionalServiceLanguage,
    defaultServiceLanguage,
    ServiceLanguage,
    translation,
} from "albertine-shared-web";
import { CatalogSourceKey } from "./CatalogCategories";
import { GooglePlace } from "./GooglePlace";
import { Tag, tagDisplayName, ValidateTag } from "./tags";
import { AssetReference } from "./types/Attachment";
import {
    categoryDisplayName,
    OfferingCategory,
} from "./types/OfferingCategory";
import {
    AgentId,
    AlbertineServiceAgentId,
    FirebaseId,
    GeoPoint,
    IsoDateTimeString,
    LongString,
    MemberId,
    ShortString,
} from "./validate";
import {
    CatalogItemEditableTranslation,
    catalogItemNameMaxLength,
    collectTranslationValues,
} from "./types/Translation";
import { excludeUndefined } from "./utils/array.util";

export const AddressComponents = z
    .array(
        z.object({
            longName: z.string(),
            shortName: z.string(),
            types: z.array(z.string()),
        }),
    )
    .optional()
    .nullable();

export type AddressComponents = z.infer<typeof AddressComponents>;

export const CatalogImage = z.object({
    id: z.string(),
    url: z.string(),
});
export type CatalogImage = z.infer<typeof CatalogImage>;

export const CityGuideLocation = z.object({
    city: z.string(),
    country: z.string(),
    key: z.string(),
});
export type CityGuideLocation = z.infer<typeof CityGuideLocation>;

export const CatalogItemSharingLogEntry = z.object({
    messageId: FirebaseId,
    memberId: MemberId,
    conversationId: FirebaseId,
    requestId: FirebaseId.nullable(),
    agentId: AgentId.nullable(),
    sentByAgent: z.boolean(),
    sharedAt: IsoDateTimeString,
});
export type CatalogItemSharingLogEntry = z.infer<
    typeof CatalogItemSharingLogEntry
>;

export const CatalogItemTranslationDe = z.object({
    addressDe: LongString.optional().nullable(),
    benefitsDe: z.string().optional().nullable(),
    bookingPolicyDe: z.string().optional().nullable(),
    descriptionDe: z.string().optional().nullable(),
    eventDatesDe: z.string().optional().nullable(),
    headlineDe: z.string().optional().nullable(),
    localInsightsDe: z.string().optional().nullable(),
    nameDe: z.string().optional().nullable(),
    travelItineraryNotesDe: z.string().optional().nullable(),
});

export const CatalogItemTranslationFr = z.object({
    addressFr: LongString.optional().nullable(),
    benefitsFr: z.string().optional().nullable(),
    bookingPolicyFr: z.string().optional().nullable(),
    descriptionFr: z.string().optional().nullable(),
    eventDatesFr: z.string().optional().nullable(),
    headlineFr: z.string().optional().nullable(),
    localInsightsFr: z.string().optional().nullable(),
    nameFr: z.string().optional().nullable(),
    travelItineraryNotesFr: z.string().optional().nullable(),
});

const CatalogItemLanguages = CatalogItemTranslationDe.merge(
    CatalogItemTranslationFr,
);

export type CatalogItemTranslationDe = z.infer<typeof CatalogItemTranslationDe>;
export type CatalogItemTranslationFr = z.infer<typeof CatalogItemTranslationFr>;
export type CatalogItemLanguage =
    | CatalogItemTranslationDe
    | CatalogItemTranslationFr;
type CatalogItemLanguages = z.infer<typeof CatalogItemLanguages>;

export const CatalogItemEditables = z
    .object({
        name: z.string().optional(),
        description: z.string().optional().nullable(),
        notes: z.string().optional().nullable(),
        source: z.string().optional().nullable(), // TODO: create zod enum out of CatalogSources
        googlePlaceId: z.string().optional().nullable(),
        address: LongString.optional().nullable(),
        addressComponents: AddressComponents.optional().nullable(),
        website: z.string().url().optional().or(z.literal("")).or(z.null()),
        phoneNumber: ShortString.optional().nullable(),
        googleUrl: z.string().url().optional().nullable(),
        geoPoint: GeoPoint.optional().nullable(),
        offeringCategories: z.array(OfferingCategory).optional().nullable(),
        tags: z.array(ValidateTag).optional().nullable(),
        supplierData: z
            .record(z.object({ benefits: z.string() }))
            .optional()
            .nullable(),
        suppliers: z.array(z.string()).optional().nullable(),
        localInsights: z.string().optional().nullable(),
        storyblokArticleId: z.number().optional().nullable(),
        storyblokArticleUuid: z.string().optional().nullable(),
        storyblokArticleSlug: z.string().optional().nullable(),
        distanceToQuery: z.number().optional().nullable(),
        published: z.boolean().optional().nullable(), // TODO: Should this be required?
        images: z.array(CatalogImage).optional().nullable(), // TODO: Should this be required?
        headline: z.string().optional().nullable(),
        benefits: z.string().optional().nullable(),
        bookingPolicy: z.string().optional().nullable(),
        albertineVerified: IsoDateTimeString.optional().nullable(), // displayed as boolean if value is set
        recentlyOpened: IsoDateTimeString.optional().nullable(), // displayed as boolean if value is set
        eventDates: z.string().optional().nullable(),
        travelItineraryNotes: z.string().optional().nullable(),
        googlePlaceData: GooglePlace.optional().nullable(),
        cityGuideLocation: CityGuideLocation.optional().nullable(),
        keyDetail: z.string().optional().nullable(),
    })
    .merge(CatalogItemLanguages);
export type CatalogItemEditables = z.infer<typeof CatalogItemEditables>;

export function newCatalogItem(): CatalogItemEditables {
    return {
        name: "",
        images: [],
    };
}

export const CatalogItemId = FirebaseId.or(z.string().uuid());
export type CatalogItemId = z.infer<typeof CatalogItemId>;
export const CatalogItemIdentifiable = z.object({ id: CatalogItemId });

export const NewCatalogItemPayload = CatalogItemEditables.omit({
    name: true,
}).extend({
    name: z.string(),
});
export type NewCatalogItemPayload = z.infer<typeof NewCatalogItemPayload>;

export const UpdateCatalogItemPayload = CatalogItemEditables.merge(
    CatalogItemIdentifiable,
);
export type UpdateCatalogItemPayload = z.infer<typeof UpdateCatalogItemPayload>;

export const CatalogItemUpdate = CatalogItemEditables.extend({
    updatedAt: IsoDateTimeString,
    updatedBy: AgentId.or(AlbertineServiceAgentId),
});
export type CatalogItemUpdate = z.infer<typeof CatalogItemUpdate>;

export const NewCatalogItem = CatalogItemUpdate.omit({
    name: true,
}).extend({
    name: z.string(),
    createdAt: IsoDateTimeString,
    createdBy: AgentId.or(AlbertineServiceAgentId),
});
export type NewCatalogItem = z.infer<typeof NewCatalogItem>;

export const CatalogItem = NewCatalogItem.extend({
    id: CatalogItemId,
    sharingLog: z.array(CatalogItemSharingLogEntry),
    shared: z.boolean(),
    archived: z.boolean(),
});
export type CatalogItem = z.infer<typeof CatalogItem>;

export const ManageCatalogItemPayload = z.discriminatedUnion("action", [
    z.object({
        action: z.literal("create"),
        item: NewCatalogItemPayload,
    }),
    z.object({
        action: z.literal("read"),
        itemId: CatalogItemId,
        itemSlug: z.string().optional(),
    }),
    z.object({
        action: z.literal("read-by-slug"),
        slug: z.string(),
    }),
    z.object({
        action: z.literal("read-by-google-place"),
        googlePlaceId: z.string(),
    }),
    z.object({
        action: z.literal("update"),
        update: UpdateCatalogItemPayload,
        imageReferences: z.array(AssetReference).nullable(),
    }),
    z.object({
        action: z.literal("translate"),
        contentToTranslate: CatalogItemEditableTranslation,
        language: AdditionalServiceLanguage,
    }),
    z.object({
        action: z.literal("batchUpdate"),
        updates: z.array(UpdateCatalogItemPayload),
    }),
    z.object({
        action: z.literal("archive"),
        itemId: CatalogItemId,
    }),
    z.object({
        action: z.literal("unarchive"),
        itemId: CatalogItemId,
    }),
]);
export type ManageCatalogItemPayload = z.infer<typeof ManageCatalogItemPayload>;

export const ManageCatalogItemResponse = z.discriminatedUnion("action", [
    z.object({
        action: z.literal("create"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("read"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("read-by-slug"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("read-by-google-place"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("update"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("translate"),
        items: z.array(CatalogItem), // TODO: Refactor away the need to have items here even though it's not used
        translation: CatalogItemEditableTranslation,
    }),
    z.object({
        action: z.literal("batchUpdate"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("archive"),
        items: z.array(CatalogItem),
    }),
    z.object({
        action: z.literal("unarchive"),
        items: z.array(CatalogItem),
    }),
]);
export type ManageCatalogItemResponse = z.infer<
    typeof ManageCatalogItemResponse
>;

const minImages = 1;

export const CatalogItemFormSchema = z.object({
    name: z
        .string({ required_error: "Add name" })
        .min(3, "Add name")
        .max(catalogItemNameMaxLength, "Limit for name is 255 characters"),
    images: z
        .array(CatalogImage, { required_error: "Add at least one image" })
        .min(minImages, "Add at least one image"),
    description: z
        .string({ required_error: "Add general description" })
        .min(3, "Add general description"),
    offeringCategory: OfferingCategory,
    localInsights: z.string().optional().nullable(),
    source: CatalogSourceKey,
    notes: z.string().optional().nullable(),
    address: LongString.optional().nullable(),
    website: z.string().url().optional().nullable(),
    phoneNumber: ShortString.optional().nullable(),
    tags: z.array(ValidateTag).optional().nullable(),
    published: z.boolean(),
    addressComponents: AddressComponents,
    googlePlaceId: z.string().optional().nullable(),
    googlePlaceData: GooglePlace.optional().nullable(),
    googleUrl: z.string().url().optional().nullable(),
    geoPoint: GeoPoint.optional().nullable(),

    headline: z.string().optional().nullable(),
    benefits: z.string().optional().nullable(),
    bookingPolicy: z.string().optional().nullable(),
    albertineVerified: IsoDateTimeString.optional().nullable(),
    recentlyOpened: IsoDateTimeString.optional().nullable(),
    eventDates: z.string().optional().nullable(),
    travelItineraryNotes: z.string().optional().nullable(),

    suppliers: z.array(z.string()).optional(),
    translations: z.record(
        AdditionalServiceLanguage,
        CatalogItemEditableTranslation.optional(),
    ),
});

export type CatalogItemFormSchema = z.infer<typeof CatalogItemFormSchema>;

export const CatalogItemPreview = CatalogItemEditables.pick({
    name: true,
    keyDetail: true,
}).extend({
    id: CatalogItemId,
    image: z.string().url().optional(),
});

export type CatalogItemPreview = z.infer<typeof CatalogItemPreview>;

export function safelyConvertLocationToGeoPoint(
    location: any,
): { latitude: number; longitude: number } | undefined {
    if (!location) return undefined;

    if (location.lat instanceof Function) {
        return {
            latitude: location.lat(),
            longitude: location.lng(),
        };
    }
    if (location.lat && location.lng) {
        return {
            latitude: location.lat,
            longitude: location.lng,
        };
    }
    if (location._lat && location._long) {
        return {
            latitude: location._lat,
            longitude: location._long,
        };
    }
    if (location._latitude && location._longitude) {
        return {
            latitude: location._latitude,
            longitude: location._longitude,
        };
    }
    if (location.latitude && location.longitude) {
        return {
            latitude: location.latitude,
            longitude: location.longitude,
        };
    }

    return { latitude: location.lat, longitude: location.lng };
}

function requiredFieldIsNotEmpty(requiredField: string | null | undefined) {
    return (requiredField || "")?.length >= 3;
}

export function hasWoaRequiredFields(
    catalogItem: CatalogItem | NewCatalogItemPayload | CatalogItemEditables,
): boolean {
    /* TODO: Switch to requiring primary category when we actually store them */
    const { name, images, description, offeringCategories } = catalogItem;
    const hasAtLeastOneImage = (images || []).length >= 1;
    const atLeastOneCategory = (offeringCategories || []).length > 0;

    return (
        requiredFieldIsNotEmpty(name) &&
        hasAtLeastOneImage &&
        requiredFieldIsNotEmpty(description) &&
        atLeastOneCategory
    );
}

export function hasTranslations(
    language: AdditionalServiceLanguage | "all",
    catalogItem: CatalogItem | NewCatalogItemPayload | CatalogItemEditables,
): boolean {
    const properties = collectTranslationValues(
        ["name", "description"],
        catalogItem,
        language,
    );

    return properties.filter(excludeUndefined).length === properties.length;
}

export function canBeSharedAsProactivity(
    catalogItem: CatalogItem,
    serviceLanguage: ServiceLanguage,
) {
    if (serviceLanguage !== defaultServiceLanguage) {
        return (
            hasTranslations(serviceLanguage, catalogItem) &&
            hasWoaRequiredFields(catalogItem)
        );
    }
    return hasWoaRequiredFields(catalogItem);
}

export function catalogItemToCatalogItemPreview(
    catalogItem: CatalogItem,
): CatalogItemPreview {
    return {
        id: catalogItem.id,
        name: catalogItem.name ?? undefined,
        keyDetail: catalogItem.keyDetail,
        image:
            catalogItem.images && catalogItem.images[0]
                ? catalogItem.images[0].url
                : undefined,
    };
}

export function getItemKeyDetailLocation(
    location: CityGuideLocation | null | undefined,
    serviceLanguage: ServiceLanguage,
): string | null {
    return location && location.city
        ? translation(`key_detail.cityguide:${location.key}`, {
              lng: serviceLanguage,
              defaultValue: location.city,
          })
        : null;
}

function getDiningKeyDetail(tags: Tag[], serviceLanguage: ServiceLanguage) {
    const cuisineTag = tags
        .sort((t1, t2) => {
            if (t1 === "cuisine:asian") return 1;
            if (t2 === "cuisine:asian") return -1;
            return t1.localeCompare(t2);
        })
        .find((tag: Tag) => tag.startsWith("cuisine:"));
    return cuisineTag
        ? translation(`key_detail.${cuisineTag}`, { lng: serviceLanguage })
        : translation("key_detail.restaurant", { lng: serviceLanguage });
}

function getTypesOrCategory(
    tags: Tag[],
    category: OfferingCategory,
    serviceLanguage: ServiceLanguage,
) {
    const types = tags
        .filter((tag: Tag) => tag.startsWith("type:"))
        .slice(0, 3)
        .map((tag: Tag) => tagDisplayName(tag, serviceLanguage))
        .join(", ");
    return types || categoryDisplayName(category, serviceLanguage);
}
export function getItemKeyDetailDescription(
    categories: OfferingCategory[] | null | undefined,
    serviceLanguage: ServiceLanguage,
    tags: Tag[] = [],
    travelItineraryNotes: string | null | undefined = null,
    eventDates: string | null | undefined = null,
): string | null {
    if (!categories || categories.length === 0) {
        return null;
    }
    const category = categories[0];

    switch (category) {
        case "dining":
            return getDiningKeyDetail(tags, serviceLanguage);
        case "accommodation":
        case "nightlife":
        case "localExperience":
        case "wellness":
            return getTypesOrCategory(tags, category, serviceLanguage);
        case "travelItinerary":
            return (
                travelItineraryNotes ||
                categoryDisplayName(category, serviceLanguage)
            );
        case "event":
            return eventDates || categoryDisplayName(category, serviceLanguage);
        default:
            return categoryDisplayName(category, serviceLanguage);
    }
}

export function getItemKeyDetail(
    categories: OfferingCategory[] | null | undefined,
    serviceLanguage: ServiceLanguage,
    tags: Tag[] = [],
    travelItineraryNotes: string | null | undefined = null,
    eventDates: string | null | undefined = null,
    location: CityGuideLocation | null | undefined = null,
): string | null {
    const result = [
        getItemKeyDetailDescription(
            categories,
            serviceLanguage,
            tags,
            travelItineraryNotes,
            eventDates,
        ),
        getItemKeyDetailLocation(location, serviceLanguage),
    ]
        .filter(excludeUndefined)
        .join(" · ");
    return result === "" ? null : result;
}
