/* eslint-disable react/prop-types */
import React from "react";
import * as ReactDOM from "react-dom/client";
import classNames from "classnames";
import {
    AlbertineI18nProvider,
    TenantProvider,
    TenantDependencyInjection,
    Lib,
    useAlbertineTranslation,
} from "albertine-shared-web";
import { BrowserRouter } from "react-router-dom";
import App from "../src/App";
import AuthProvider from "../src/context/auth";
import OpenSearchProvider from "../src/context/opensearch";
import injectSharedWebCss from "../src/injectSharedWeb";
import ScreenStackProvider from "../src/context/screenStack";
import Avatar from "../src/components/Avatar";
import "../src/main.css";
import {
    ErrorBoundary,
    registerGlobalErrorListeners,
    setupLogErrorTenant,
} from "../src/error";
import FullScreenError from "../src/screens/FullScreenError";

setupLogErrorTenant("albertine");
registerGlobalErrorListeners();
injectSharedWebCss();

const dependencyInjection: TenantDependencyInjection = {
    avatar: {
        inverseInitials: false,
        serviceAgentAvatarEmblem: () => <Lib.Asset.AlbertineEmblem />,
    },
    bookings: {
        emptyState: {
            ServiceEmblem: () => <Lib.Asset.AlbertineEmblem />,
            CtaButton: (props) => {
                const { onClick } = props;
                const t = useAlbertineTranslation();
                return (
                    <Lib.Button.Primary onClick={onClick}>
                        {t("bookings__empty_state_button")}
                    </Lib.Button.Primary>
                );
            },
        },
        ProfileButton: (props) => {
            const { className, onClick, userId, userName } = props;
            return (
                <Lib.Button.Ghost
                    onClick={onClick}
                    className={classNames(className)}
                >
                    <Avatar.Member id={userId} fullName={userName} />
                </Lib.Button.Ghost>
            );
        },
    },
    navigation: {
        BookingsNavigationItem: (props: { isActive: boolean }) => {
            const { isActive } = props;
            const t = useAlbertineTranslation();

            return isActive ? (
                <>
                    <Lib.Icon.EventAvailable.Medium.Gray10 />
                    <Lib.Label.XSmall.MediumBold.TextColor02>
                        {t("navigation_title_bookings")}
                    </Lib.Label.XSmall.MediumBold.TextColor02>
                </>
            ) : (
                <>
                    <Lib.Icon.EventAvailable.Medium.Gray40 />
                    <Lib.Label.XSmall.MediumBold.TextColor03>
                        {t("navigation_title_bookings")}
                    </Lib.Label.XSmall.MediumBold.TextColor03>
                </>
            );
        },
    },
    onboarding: {
        OnboardingButtonIcon: () => <Lib.Icon.LocationOn.Medium.Gray0 />,
    },
};

function Root() {
    return (
        <React.StrictMode>
            <TenantProvider
                value={{
                    tenant: "albertine",
                    dependencyInjection,
                }}
            >
                <ErrorBoundary
                    fallback={<FullScreenError text="Something went wrong." />}
                >
                    <BrowserRouter>
                        <AuthProvider>
                            <AlbertineI18nProvider>
                                <OpenSearchProvider>
                                    <ScreenStackProvider>
                                        <App />
                                    </ScreenStackProvider>
                                </OpenSearchProvider>
                            </AlbertineI18nProvider>
                        </AuthProvider>
                    </BrowserRouter>
                </ErrorBoundary>
            </TenantProvider>
        </React.StrictMode>
    );
}

ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);
