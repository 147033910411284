import { z } from "zod";
import { httpsCallable } from "firebase/functions";
import { functions } from "../Firebase";
import { MemberDataDeviceLocation } from "../../../lmt/src/common/types/MemberData";
import { logError } from "../error";
import {
    getLocationPermission,
    setLocationPermission,
} from "../utils/localStorage.util";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const noHttps = window.location.protocol === "http:";
const treatLocationAsDenied = isSafari && noHttps;

const LocationResult = z.enum(["granted", "denied", "prompt"]);
export type LocationResult = z.infer<typeof LocationResult>;
interface MemberDeviceLocation {
    deviceLocation: MemberDataDeviceLocation;
}

export async function getCurrentLocationPermission(): Promise<LocationResult> {
    try {
        const storedPermission = getLocationPermission();

        if (storedPermission?.error) {
            return storedPermission.state;
        }

        const permissionStatus = await navigator.permissions.query({
            name: "geolocation",
        });
        return permissionStatus.state;
    } catch (error) {
        logError("getCurrentLocationPermission", error);
        return "denied";
    }
}

async function getGeolocation(): Promise<GeolocationPosition | null> {
    if ("geolocation" in navigator) {
        try {
            const geolocationPosition: GeolocationPosition | null =
                await new Promise((resolve) => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => resolve(position),
                        (error) => {
                            logError(
                                "getGeolocation getCurrentPosition errorcallback",
                                error,
                            );
                            resolve(null);
                        },
                    );
                });

            return geolocationPosition;
        } catch (error) {
            logError("getGeolocation general error", error);
            return null;
        }
    }

    return null;
}

async function updateDeviceLocation(update: MemberDeviceLocation) {
    try {
        await httpsCallable(functions, "memberUpdateMemberData")(update);
    } catch (error) {
        logError("updateDeviceLocation", error);
    }
}

export async function requestLocationPermission() {
    if (treatLocationAsDenied) {
        setLocationPermission({
            state: "denied",
            error: true,
        });
        return "denied";
    }

    const permission = await getCurrentLocationPermission();

    if (permission !== "denied") {
        const geolocationPosition = await getGeolocation();

        if (geolocationPosition) {
            const { coords } = geolocationPosition;

            await updateDeviceLocation({
                deviceLocation: {
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                },
            });

            setLocationPermission({
                state: "granted",
                error: false,
            });
            return "granted";
        }

        setLocationPermission({
            state: "denied",
            error: true,
        });
        return "denied";
    }

    setLocationPermission({
        state: permission,
        error: false,
    });
    return permission;
}
