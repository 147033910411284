import React from "react";
import { Lib, useTenant } from "albertine-shared-web";
import Screen from "./Screen";
import "./FullScreenError.css";

type LoadingScreenProps = {
    text: string;
};

function FullScreenError({ text }: LoadingScreenProps) {
    const { tenant } = useTenant();

    return (
        <Screen.StartBg01 textColorInversable={tenant === "hugoboss"}>
            <Lib.Flex.Column
                alignItems="center"
                justifyContent="center"
                className="full-screen-error"
                gap="value16"
            >
                <Lib.Label.Large.TextColor01 transform="uppercase">
                    {text}
                </Lib.Label.Large.TextColor01>
                <Lib.Button.Primary onClick={() => window.location.reload()}>
                    Try again
                </Lib.Button.Primary>
            </Lib.Flex.Column>
        </Screen.StartBg01>
    );
}

export default FullScreenError;
