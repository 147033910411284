import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Lib } from "albertine-shared-web";
import { useRoutes } from "../routes";
import Screen from "./Screen";
import "./Onboarding.css";
import { Member } from "../../../lmt/src/common/types/Member";
import { OnboardingSteps } from "../types/OnboardingSteps";
import {
    getOnboardingNextStep,
    setOnboardingNextStep,
    removeOnboardingNextStep,
} from "../utils/localStorage.util";
import {
    finishOnboardingFlow,
    updateMemberPreferences,
} from "../api/firestore";
import OnboardingProgress from "../components/OnboardingProgress";
import Transition from "./Onboarding/OnboardingTransition";

interface Props {
    currentMember: Member | undefined | null;
}

function Onboarding(props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextStep, setNextStep] = useState<OnboardingSteps | null>(
        getOnboardingNextStep(),
    );
    const [progress, setProgress] = useState<number>(0);
    const { currentMember } = props;
    const navigate = useNavigate();
    const { url } = useRoutes();

    useEffect(() => {
        const finishedOnboarding = currentMember?.hasOnboardingShown;
        if (finishedOnboarding) {
            setNextStep(null);
            removeOnboardingNextStep();
            navigate(url.notificationPermission);
        } else if (nextStep !== null) {
            setOnboardingNextStep(nextStep);
            navigate(url.onboardingStep(nextStep));
        } else {
            setIsLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMember, nextStep]);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    return isLoading ? (
        <Screen.Onboarding>
            <Lib.Flex.Column
                className="onboarding-height-full"
                justifyContent="center"
                alignItems="center"
            >
                <Lib.LoadingSpinner />
            </Lib.Flex.Column>
        </Screen.Onboarding>
    ) : (
        <Screen.Onboarding>
            <Lib.ContentCenter.Small className="onboarding-height-full">
                <Lib.Flex.Column
                    justifyContent="space-between"
                    className="onboarding-container"
                >
                    <Lib.Flex.Column>
                        <OnboardingProgress progress={progress} />
                    </Lib.Flex.Column>

                    <Transition>
                        <Outlet
                            context={{
                                member: currentMember,
                                updateMemberPreferences,
                                setNextStep,
                                finishOnboardingFlow,
                                setProgress,
                                setIsLoading,
                            }}
                        />
                    </Transition>
                </Lib.Flex.Column>
            </Lib.ContentCenter.Small>
        </Screen.Onboarding>
    );
}

export default Onboarding;
