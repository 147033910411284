import { OfferingCategory } from "../../../lmt/src/common/types/OfferingCategory";
import { OpenSearchCatalogHitSource } from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import { Filter } from "../types/Filter";

export function offeringCategoriesFilterFn(
    article: OpenSearchCatalogHitSource,
    activeFilter: OfferingCategory | "all",
): boolean {
    if (activeFilter === "all") return true;
    return article.offeringCategories?.includes(activeFilter) ?? false;
}

export function cleanupCategoriesFilters(
    articles: OpenSearchCatalogHitSource[] | [],
    filters: Filter<OfferingCategory | "all">[],
): boolean {
    return filters.some((filter) => {
        if (filter.id === "all") {
            return true;
        }
        const category = filter.id && OfferingCategory.safeParse(filter.id);
        return articles.some((article) =>
            category.data
                ? article.offeringCategories?.includes(category.data)
                : false,
        );
    });
}

export function cityGuideLocationFilterFn(
    article: OpenSearchCatalogHitSource,
    activeFilter: string | "all",
): boolean {
    if (activeFilter === "all") return true;
    return article.cityGuideLocation?.includes(activeFilter) ?? false;
}

export function cleanupCityGuideLocationFilters(
    articles: OpenSearchCatalogHitSource[] | [],
    filters: Filter<string | "all">[],
): boolean {
    return filters.some((filter) =>
        articles.some((article) => article.cityGuideLocation === filter.id),
    );
}
