import { z } from "zod";
import { NumericStringOrNumber } from "../validate";

/*
 * EVERYTHING IN THIS FILE IS DEPRECATED AND SHOULD NOT BE USED IN ANY NEW FEATURES
 * - Chat uses CatalogArticleItem for showing old Storyblok articles that exists in Firestore
 * - Catalog article previews still use ArticlePreview, needs FullArticle.tsx to be refactored
 */

const AssetResponse = z
    .object({
        filename: z.string(),
        id: z.number().nullable(),
    })
    .nullable();

export type AssetResponse = z.infer<typeof AssetResponse>;

export const ArticleContentPlace = z.object({
    lat: NumericStringOrNumber,
    lon: NumericStringOrNumber,
    uid: z.string().optional().nullable(),
    name: z.string(),
    address: z.string(),
    country: z.string().optional(),
    component: z.literal("googlePlace"),
    googlePlaceId: z.string(),
});

export const ArticleCallToActionEnum = z.enum([
    "request_more_info",
    "ask_lifestyle_manager",
    "place_request",
]);

export type ArticleCallToActionEnum = z.infer<typeof ArticleCallToActionEnum>;

export const ArticleCallToAction = z
    .string()
    .transform((value) => {
        if (value === "") {
            return "place_request";
        }
        return value;
    })
    .refine((value) => ArticleCallToActionEnum.options.includes(value as any), {
        message: "Invalid enum value",
    });

export type ArticleContentPlace = z.infer<typeof ArticleContentPlace>;

export const ArticleContent = z.object({
    catalogItemId: z.string().nullable().optional(),
    title: z.string(),
    content: z.string(),
    component: z.literal("article"),
    coverImage: AssetResponse,
    additionalImages: z.array(AssetResponse),
    place: z.array(ArticleContentPlace).optional().nullable(),
    insight: z.string().optional().nullable(),
    callToAction: ArticleCallToAction.optional().nullable(),
});

export type ArticleContent = z.infer<typeof ArticleContent>;

export const CatalogArticleItem = z.object({
    uuid: z.string(),
    fullSlug: z.string(),
    createdAt: z.string().optional(),
    tagList: z.array(z.string()),
    slug: z.string(),
    publishedAt: z.string().optional(),
    firstPublishedAt: z.string().optional(),
    content: ArticleContent,
});

export type CatalogArticleItem = z.infer<typeof CatalogArticleItem>;

const ArticlePreviewPlace = z.object({
    lat: NumericStringOrNumber,
    lon: NumericStringOrNumber,
    uid: z.string().optional(),
    name: z.string(),
    address: z.string(),
    country: z.string().optional(),
    component: z.literal("googlePlace"),
    googlePlaceId: z.string(),
});

const ArticlePreview = z.object({
    _uid: z.string().optional(),
    coverImage: AssetResponse,
    additionalImages: z.array(AssetResponse),
    component: z.literal("article"),
    content: z.string(),
    title: z.string(),
    place: z.array(ArticlePreviewPlace),
    insight: z.string().optional(),
    callToAction: ArticleCallToAction.optional().nullable(),
    keyDetail: z.string().optional().nullable(),
    headline: z.string().optional().nullable(),
    website: z.string().url().optional().nullable(),
    tags: z.array(z.string()).optional().nullable(),
    benefits: z.string().optional().nullable(),
});
export type ArticlePreview = z.infer<typeof ArticlePreview>;
export type ArticlePreviewPlace = z.infer<typeof ArticlePreviewPlace>;

const ArticleDeepLinkSlug = z.object({
    uuid: z.string(),
    name: z.string(),
    slugDeepLink: z.string(),
});
type ArticleDeepLinkSlug = z.infer<typeof ArticleDeepLinkSlug>;

export function mapArticlesToDeepLink(articles: CatalogArticleItem[]) {
    const result: Array<ArticleDeepLinkSlug> = [];
    if (articles.length === 0) return result;

    articles.forEach((article) => {
        result.push({
            uuid: article.uuid,
            name: article.content.title,
            slugDeepLink: `albertineapp://${article.fullSlug}`,
        });
    });

    return result;
}
