import React, {
    ChangeEventHandler,
    useCallback,
    useEffect,
    useState,
} from "react";
import "./NumberInput.css";
import classNames from "classnames";
import { Lib } from "albertine-shared-web";
import examples from "libphonenumber-js/mobile/examples";
import {
    formatIncompletePhoneNumber,
    getExampleNumber,
    isSupportedCountry,
    parsePhoneNumberFromString,
} from "libphonenumber-js";
import { setLoginPhoneNumber } from "../utils/localStorage.util";
import { PhoneNumber } from "../types/PhoneNumber";

type PhoneNumberInputProps = {
    className?: string;
    phoneNumber: PhoneNumber;
    setPhoneNumber: (value: PhoneNumber) => void;
    onContinue: () => void;
};

export function PhoneNumberInput({
    className,
    phoneNumber,
    setPhoneNumber,
    onContinue,
}: PhoneNumberInputProps) {
    const formatPhoneNumber = useCallback((newPhoneNumber: PhoneNumber) => {
        if (!isSupportedCountry(newPhoneNumber.countryCode)) {
            console.warn(
                `Unsupported country code: ${newPhoneNumber.countryCode}`,
            );
            return newPhoneNumber.phoneNumber;
        }

        return formatIncompletePhoneNumber(newPhoneNumber.phoneNumber, {
            defaultCountry: newPhoneNumber.countryCode,
            defaultCallingCode: newPhoneNumber.dialCode.replace("+", ""),
        });
    }, []);

    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(
        formatPhoneNumber(phoneNumber),
    );
    useEffect(() => {
        setFormattedPhoneNumber(formatPhoneNumber(phoneNumber));
        setLoginPhoneNumber({
            ...phoneNumber,
            countryCode: phoneNumber.countryCode,
        });
    }, [formatPhoneNumber, phoneNumber]);

    const placeholder = isSupportedCountry(phoneNumber.countryCode)
        ? (getExampleNumber(
              phoneNumber.countryCode,
              examples,
          )?.formatNational() ?? "0412345678")
        : "0412345678";

    const resolvedClassName = classNames("number_input", className);
    const onChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            // TODO: handle selection ranges when user changes their phone number
            //       in such a way that the cursor position changes to the end of the input.
            //       This usually seems to happen when the formatter adds or removes spaces.

            const cleanedPhoneNumber = event.target.value.replace(/\s/g, "");
            const parsedPhoneNumber = parsePhoneNumberFromString(
                cleanedPhoneNumber,
                isSupportedCountry(phoneNumber.countryCode)
                    ? phoneNumber.countryCode
                    : undefined,
            );

            setPhoneNumber({
                ...phoneNumber,
                phoneNumber: cleanedPhoneNumber,
                parsedPhoneNumber:
                    parsedPhoneNumber?.format("E.164") ??
                    phoneNumber.dialCode + cleanedPhoneNumber,
                isValid: parsedPhoneNumber?.isValid() ?? false,
            });
        },
        [phoneNumber, setPhoneNumber],
    );

    return (
        <input
            className={resolvedClassName}
            onChange={onChangeHandler}
            onKeyUp={(event) => {
                if (event.key === "Enter" && phoneNumber.isValid) {
                    onContinue();
                }
            }}
            value={formattedPhoneNumber}
            placeholder={placeholder}
        />
    );
}

type CodeInputProps = {
    code: string;
    setCode: (value: string) => void;
    className?: string;
    loading?: boolean;
};

export function CodeInput(props: CodeInputProps) {
    const { code, setCode, className, loading } = props;

    const numberInputClass = classNames(
        "number_input",
        "number_input__code",
        className,
    );

    return (
        <Lib.Flex.Row>
            <input
                value={code}
                onChange={(event) => {
                    setCode(event.target.value.replace(/\s/g, ""));
                }}
                placeholder="000000"
                className={numberInputClass}
                inputMode="numeric"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                maxLength={6}
            />
            {loading && (
                <Lib.LoadingSpinner className="number-input__loading" />
            )}
        </Lib.Flex.Row>
    );
}
