import { z } from "zod";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { MediumLongString } from "../validate";

const OnboardingContentFeedback = z.enum([
    "neutral",
    "like",
    "heart",
    "dislike",
]);

export type OnboardingContentFeedback = z.infer<
    typeof OnboardingContentFeedback
>;

const OnboardingContent = z.object({
    title: z.string(),
    description: MediumLongString,
    coverImageUrl: z.string(),
    feedback: OnboardingContentFeedback,
});

export const OnboardingBenefitContent = z
    .object({
        group: z.string(),
    })
    .merge(OnboardingContent);

export type OnboardingBenefitContent = z.infer<typeof OnboardingBenefitContent>;

export const OnboardingRecommendationContent = z
    .object({
        category: z.string(),
    })
    .merge(OnboardingContent);

export type OnboardingRecommendationContent = z.infer<
    typeof OnboardingRecommendationContent
>;

export const OnboardingTopCity = z.object({
    city: z.string(),
    country: z.string(),
    countryCode: z.string().optional(),
    googlePlaceId: z.string(),
    appLocale: z.string().optional().nullable(),
});

export type OnboardingTopCity = z.infer<typeof OnboardingTopCity>;

const UpcomingDiningPlansAnswer = z.enum(["yes", "no"]);
const UpcomingTravelPlansAnswer = z.enum(["yes", "no", "some"]);
const HasUsedConciergeAnswer = z.enum(["yes", "no", "maybe"]);
export const HasUsedConciergeFor = z.enum([
    "saveTime",
    "discoverNewExperiences",
    "accessBenefits",
    "somethingElse",
]);
export const OnboardingSteps = z.enum([
    "start",
    "name",
    "cities",
    "conciergeExperience",
    "recommendations",
    "travelPlans",
    "diningPlans",
    "notifications",
]);
export type OnboardingSteps = z.infer<typeof OnboardingSteps>;

export type UpcomingDiningPlansAnswer = z.infer<
    typeof UpcomingDiningPlansAnswer
>;
export type UpcomingTravelPlansAnswer = z.infer<
    typeof UpcomingTravelPlansAnswer
>;
export type HasUsedConciergeAnswer = z.infer<typeof HasUsedConciergeAnswer>;
export type HasUsedConciergeFor = z.infer<typeof HasUsedConciergeFor>;

export const HasUsedConciergeAnswerTextMap: {
    [key in HasUsedConciergeAnswer]: string;
} = {
    yes: "Has used concierge before.",
    maybe: "Not sure about previous concierge experience.",
    no: "Has not used concierge before.",
};

export const HasUsedConciergeForTextMap: {
    [key in HasUsedConciergeFor]: string;
} = {
    saveTime: "Saving time on day-to-day life admin",
    discoverNewExperiences: "Discovering new experiences",
    accessBenefits: "Preferential access and benefits",
    somethingElse: "Something else",
};

export const OnboardingPreferences = z.object({
    greetingsName: z.string().optional().nullable(),
    topCities: z.array(OnboardingTopCity).optional().nullable(),
    benefits: z.array(OnboardingBenefitContent).optional().nullable(),
    recommendations: z
        .array(OnboardingRecommendationContent)
        .optional()
        .nullable(),
    anyUpcomingTravelPlans: UpcomingTravelPlansAnswer.optional().nullable(),
    upcomingTravelQuery: z.string().optional().nullable(),
    anyUpcomingDiningPlans: UpcomingDiningPlansAnswer.optional().nullable(),
    upcomingDiningQuery: z.string().optional().nullable(),
    hasUsedConcierge: HasUsedConciergeAnswer.optional().nullable(),
    hasUsedConciergeFor: HasUsedConciergeFor.optional()
        .nullable()
        .or(z.string()),
});

export type OnboardingPreferences = z.infer<typeof OnboardingPreferences>;

export const OnboardingPreferencesDoc = OnboardingPreferences.and(
    z.object({
        createdAt: FirebaseTimestamp,
        updatedAt: FirebaseTimestamp.nullable().optional(),
    }),
);
export type OnboardingPreferencesDoc = z.infer<typeof OnboardingPreferencesDoc>;
