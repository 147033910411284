import React from "react";
import { Lib, ServiceLanguage } from "albertine-shared-web";
import {
    findTranslations,
    OpenSearchCatalogHitSource,
} from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import "./DynamicThemeCard.css";

interface Props {
    article: OpenSearchCatalogHitSource;
    language: ServiceLanguage;
}

function DynamicThemeCard(props: Props) {
    const { article, language } = props;
    const { images } = article;

    const data = findTranslations(article, language);

    const { name, keyDetail, headline } = data;

    return (
        <Lib.Flex.Column gap="value20">
            <Lib.RemoteImage.Card.Wide src={images?.[0]} alt={name} />
            <Lib.Flex.Column gap="value8">
                <Lib.Heading.H2.XBold className="dynamic-theme-card__title">
                    {name}
                </Lib.Heading.H2.XBold>
                <Lib.Label.Medium.MediumBold>
                    {keyDetail}
                </Lib.Label.Medium.MediumBold>
                <Lib.Paragraph.Medium.Reqular>
                    {headline}
                </Lib.Paragraph.Medium.Reqular>
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export default DynamicThemeCard;
