import React from "react";
import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { mainNavigationRoutes, useRoutes } from "../routes";
import "./Navigation.css";
import ListenToConversation from "../loaders/ListenToConversation";

type NavigationProps = {
    conversationId: string | undefined;
};

function Navigation(props: NavigationProps) {
    const { conversationId } = props;
    const { url, currentNavigationRoute } = useRoutes();
    const navigation = useNavigate();
    const t = useAlbertineTranslation();
    const { dependencyInjection } = useTenant();

    const conversationIcon =
        currentNavigationRoute === mainNavigationRoutes.conversations ? (
            <Lib.Icon.Forum.Medium.Gray10 />
        ) : (
            <Lib.Icon.Forum.Medium.Gray40 />
        );

    const NavigationIconWithUnreadBadge = conversationId ? (
        <ListenToConversation conversationId={conversationId}>
            {(conversation) => (
                <div className="navigation__item-conversations-unread-container">
                    <div className="navigation__item-conversations-unread-count">
                        {conversation &&
                        conversation.unreadMessagesByMember &&
                        conversation.unreadMessagesByMember?.length > 0 ? (
                            <Lib.Badge
                                count={
                                    conversation.unreadMessagesByMember.length
                                }
                            />
                        ) : null}
                    </div>
                    {conversationIcon}
                </div>
            )}
        </ListenToConversation>
    ) : (
        conversationIcon
    );
    return (
        <Lib.Flex.Row className="navigation">
            {/* <Lib.Flex.Column
                justifyContent="center"
                alignItems="center"
                className={classNames("navigation__item")}
                gap="value4"
                onClick={() => navigation(url.discover)}
            >
                {currentNavigationRoute === mainNavigationRoutes.discover ? (
                    <>
                        <div className="navigation__item__discover-icon navigation__item__discover-icon--active">
                            <Lib.Asset.AlbertineEmblem />
                        </div>

                        <Lib.Label.XSmall.MediumBold.TextColor01>
                            {t("navigation_title_discover")}
                        </Lib.Label.XSmall.MediumBold.TextColor01>
                    </>
                ) : (
                    <>
                        <div className="navigation__item__discover-icon">
                            <Lib.Asset.AlbertineEmblem />
                        </div>
                        <Lib.Label.XSmall.MediumBold.TextColor02>
                            {t("navigation_title_discover")}
                        </Lib.Label.XSmall.MediumBold.TextColor02>
                    </>
                )}
            </Lib.Flex.Column> */}
            <Lib.Flex.Column
                justifyContent="center"
                alignItems="center"
                className={classNames("navigation__item")}
                gap="value4"
                onClick={() => navigation(url.bookings)}
            >
                {dependencyInjection?.navigation.BookingsNavigationItem({
                    isActive:
                        currentNavigationRoute ===
                        mainNavigationRoutes.bookings,
                })}
            </Lib.Flex.Column>

            <Lib.Flex.Column
                justifyContent="center"
                alignItems="center"
                className={classNames("navigation__item")}
                gap="value4"
                onClick={() => navigation(url.conversations)}
            >
                {currentNavigationRoute ===
                mainNavigationRoutes.conversations ? (
                    <>
                        {NavigationIconWithUnreadBadge}
                        <Lib.Label.XSmall.MediumBold.TextColor02>
                            {t("navigation_title_conversations")}
                        </Lib.Label.XSmall.MediumBold.TextColor02>
                    </>
                ) : (
                    <>
                        {NavigationIconWithUnreadBadge}
                        <Lib.Label.XSmall.MediumBold.TextColor03>
                            {t("navigation_title_conversations")}
                        </Lib.Label.XSmall.MediumBold.TextColor03>
                    </>
                )}
            </Lib.Flex.Column>
        </Lib.Flex.Row>
    );
}

export default Navigation;
